import { navigate } from "gatsby";
import React, { useCallback, useEffect, useRef, useState } from "react";

import "../../styles/cities_list.scss";
import { useScrollCarousel } from "../../hooks/useScrollCarousel";

const City = ({
  data = {
    city: "",
    hovered: false,
  },
  onMouseOver = (x: any) => null,
  onMouseOut = (x: any) => null,
  onClick = (x: any) => null,
}) => {
  const onMouseOverCb = () => {
    onMouseOver(data);
  };

  const onMouseOutCb = () => {
    onMouseOut(data);
  };

  const onClickCb = () => {
    onClick(data);
  };

  return (
    <div
      className={`map_pin_wrapper ${
        data.hovered ? "map_pin_wrapper--hovered" : ""
      }`}
      onMouseOver={onMouseOverCb}
      onClick={onClickCb}
      onMouseOut={onMouseOutCb}
    >
      <div className={`map_pin`}>{data.city}</div>
    </div>
  );
};

const CitiesList = ({
  data = [],
  highlightLocation,
  unHighlightLocation,
  goToLocation,
}) => {
  const { wrapperProps, scrollBack, scrollNext } = useScrollCarousel(
    data.length
  );

  return (
    <div className="cities_list">
      <div className="hide_scrollbar">
        <div className="cities_list_wrapper" {...wrapperProps}>
          {data.map((pin) => (
            <City
              key={pin.city}
              data={pin}
              onMouseOver={highlightLocation}
              onMouseOut={unHighlightLocation}
              onClick={goToLocation}
            />
          ))}
        </div>
      </div>
      <div className="arrows">
        <div onClick={scrollBack} className="arrow_left" />
        <div onClick={scrollNext} className="arrow_right" />
      </div>
    </div>
  );
};

export default CitiesList;
